import React from "react";
import "./adblock-modal.css";
import styled from "styled-components";

type AdblockModalProps = {
  warningLiteral: string;
  closeLiteral: string;
  visibleItems: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
};

const Popup = styled.div`
  background-color: var(--color-secondary-600);
  color: var(--color-primary-100);
  `;
const CloseButton = styled.button`
  background: var(--color-primary-700);
  color: var(--color-primary-100);
  padding: 6px 13px;
  border-radius: var(--border-radius);
  border: none;
  cursor: pointer;
  `;

export default function AdblockModal(props: Readonly<AdblockModalProps>) {
  const { visibleItems } = props;
  const [ visible, setVisible] = visibleItems;
  if (!visible) {
    return null;
  }
  return <Popup className="popup">
    <div className="popup-content">
      <p>{props.warningLiteral}</p>
      <CloseButton onClick={() => setVisible(false)}>{props.closeLiteral}</CloseButton>
    </div>
  </Popup>;
}
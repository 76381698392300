import { Helmet } from "react-helmet";

function HelmetSetup() {
  return (
    <Helmet>
      <link
        rel="shortcut icon"
        href="https://cdn.traveltool.es/traveltool/images/favicon.ico"
        type="image/x-icon"
      />
    </Helmet>
  );
}

export default HelmetSetup;

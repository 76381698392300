import { useEffect, useState } from 'react';
import adblockHelper from '../helpers/adblock.helper';

function useAdblockHandler(pageWithModels: any) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    adblockHelper.loadOilJsScript(setVisible);
  }, [pageWithModels]);

  return [visible, setVisible] as const;
}

export default useAdblockHandler;

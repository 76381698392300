import { createContext } from "react";

type ContextProviderType = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};
const AdblockModalContext = createContext<ContextProviderType>({
  visible: false,
  setVisible: (value: boolean) => value,
});
export default AdblockModalContext;

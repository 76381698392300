const AdblockHelper = {
  loadOilJsScript(setVisible: (value: React.SetStateAction<boolean>) => void) {
    const oilJsUrl =
     'https://cdn.traveltool.es/resources/javascript/home/traveltool/cookies/lib/oil.1.3.5-RELEASE.min.js';
    fetch(oilJsUrl, {
    method: 'HEAD',
    mode: 'no-cors',
    }).then((response) => {
      if (!response) {
        setVisible(true);
      }
    }).catch((error) => {
      console.error('Error fetching oil.js script:', error);
      setVisible(true);
    });
  },
};

export default AdblockHelper;

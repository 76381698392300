import { Suspense, useContext, useMemo } from 'react';
import PageContext from './context';
import ComponentModelRs from '../dto/component/response/component-model-rs';
import Page from '../models/page';
import CssUtils from '../utils/css.utils';
import "../styles/Home.css";
import "../styles/Home.scss";
import "./Product.css";
import ProductError from '../components/product/error';
import ProductsNoLazyRenderer from '../renderers/products-no-lazy-renderer';
import AdblockModal from '../components/adblock-modal/adblock-modal';
import AdblockModalContext from '../components/adblock-modal/adblock.modal.context';
import HelmetSetup from '../components/shared/HelmetSetup';
import useAdblockHandler from '../hooks/useAdblockHandler';

function Products() {
  const pageWithModels = useContext(PageContext);
  const [visible, setVisible] = useAdblockHandler(pageWithModels);
  const contextMemo = useMemo(() => ({ visible, setVisible }), []);
  const errorImage = "https://www.traveltool.es/traveltool/images/product-error.png";
  const errorText = "Este producto no está disponible";

  return (
    <div data-testid="products">
      <Suspense>
        <main>
          <AdblockModal warningLiteral={pageWithModels?.literals?.["AdblockerWarning"] as string}
                        closeLiteral={pageWithModels?.literals?.["Close"] as string}
                        visibleItems={[visible, setVisible]} />
          <section style={CssUtils.injectPageCSSVariables(pageWithModels?.page as Page)}>
            <HelmetSetup />
            <AdblockModalContext.Provider value={contextMemo}>
              <div>
                {pageWithModels?.page?.layoutInstance
                  ? pageWithModels.page.layoutInstance.componentInstanceLayoutInstances.map(
                    (componentInstanceLayoutInstance) => (
                      <div
                        key={componentInstanceLayoutInstance.componentInstance.component.code}
                        id={componentInstanceLayoutInstance.componentInstance.component.code}
                        style={CssUtils.injectComponentCSSVariables(componentInstanceLayoutInstance.componentInstance)}
                      >
                        {ProductsNoLazyRenderer({
                          componentInstance: componentInstanceLayoutInstance.componentInstance,
                          models: pageWithModels.models as ComponentModelRs[],
                          environment: pageWithModels.environment as string
                        })}
                      </div>
                    )
                  )
                  : <ProductError errorImage={errorImage} errorText={errorText} />}
              </div>
            </AdblockModalContext.Provider>
          </section>
        </main>
      </Suspense>
    </div>
  );
}

export default Products;
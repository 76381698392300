import { Suspense, useContext, useMemo } from 'react';
import ProductNoLazyRenderer from '../renderers/product-no-lazy-renderer';
import PageContext from './context';
import ComponentModelRs from '../dto/component/response/component-model-rs';
import Page from '../models/page';
import CssUtils from '../utils/css.utils';
import "../styles/Home.css";
import "../styles/Home.scss";
import "./Product.css";
import ProductError from '../components/product/error';
import AdblockModal from '../components/adblock-modal/adblock-modal';
import AdblockModalContext from '../components/adblock-modal/adblock.modal.context';
import HelmetSetup from '../components/shared/HelmetSetup';
import useAdblockHandler from '../hooks/useAdblockHandler';

type ProductProps = {
  expired?: boolean;
};

function Product({ ...props }: Readonly<ProductProps>) {
  const pageWithModels = useContext(PageContext);
  const [visible, setVisible] = useAdblockHandler(pageWithModels);
  const contextMemo = useMemo(() => ({ visible, setVisible }), []);
  const errorImage = "https://www.traveltool.es/traveltool/images/product-error.png";
  const errorText = "Este producto no está disponible";

  return (
    <Suspense>
      <main>
        <AdblockModal warningLiteral={pageWithModels?.literals?.["AdblockerWarning"] as string}
                      closeLiteral={pageWithModels?.literals?.["Close"] as string}
                      visibleItems={[visible, setVisible]} />
        <section style={CssUtils.injectPageCSSVariables(pageWithModels?.page as Page)}>
          <HelmetSetup />
          <AdblockModalContext.Provider value={contextMemo}>
            <div>
              {pageWithModels?.page && pageWithModels.page.layoutInstance
                ? pageWithModels.page.layoutInstance.componentInstanceLayoutInstances.map(
                  (componentInstanceLayoutInstance) => (
                    <div
                      key={componentInstanceLayoutInstance.componentInstance.component.code}
                      id={componentInstanceLayoutInstance.componentInstance.component.code}
                      style={CssUtils.injectComponentCSSVariables(componentInstanceLayoutInstance.componentInstance)}
                    >
                      {componentInstanceLayoutInstance.componentInstance.component.code === "product" &&
                      (props.expired || !pageWithModels.models?.find(m => m.componentCode === "product")?.success) ? (
                        <ProductError errorImage={errorImage} errorText={errorText} />
                      ) : (
                        ProductNoLazyRenderer({
                          componentInstance: componentInstanceLayoutInstance.componentInstance,
                          models: pageWithModels.models as ComponentModelRs[],
                          environment: pageWithModels.environment as string
                        })
                      )}
                    </div>
                  )
                )
                : <ProductError errorImage={errorImage} errorText={errorText} />}
            </div>
          </AdblockModalContext.Provider>
        </section>
      </main>
    </Suspense>
  );
}

export default Product;